<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="40rem"
    class="invite-to-ai-chat-modal"
    title="Invite to AI chats"
  >
    <div class="invite-to-ai-chat-modal-container">
      <div class="invite-to-ai-chat-modal-container__input-feild">
        <tagify-email
          placeholder="Enter email"
          @fetchEmail="fetchedEmail"
          :isSubmitted="isSubmitted"
        ></tagify-email>
      </div>
      <a-button
        @click="inviteEmails()"
        class="invite-to-ai-chat-modal-container__btn"
        :loading="loading"
      >
        Invite Emails
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import TagifyEmail from "../PdbComponents/TagifyEmail.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      selectedEmail: [],
      isSubmitted: false,
      loading: false,
    };
  },
  components: {
    TagifyEmail,
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapGetters({
      activeSession: "aiChat/activeSession",
    }),
  },
  methods: {
    ...mapActions({
      sendSessionInvitation: "aiChat/sendSessionInvitation",
    }),
    closeModal() {
      this.isSubmitted = true;
      this.$emit("close-modal");
    },
    fetchedEmail(val) {
      this.selectedEmail = val;
    },
    async inviteEmails() {
      this.isSubmitted = false;
      this.loading = true;
      const baseUrl = process.env.VUE_APP_REDIRECTION_URL;
      const link = `${baseUrl}/glocal-AI/${this.activeSession.id}`;
      const payload = {
        emails: this.selectedEmail,
        link: link,
        sessionId: this.activeSession.id,
      };
      await this.sendSessionInvitation(payload);
      this.isSubmitted = true;
      this.loading = false;
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss">
.invite-to-ai-chat-modal {
  width: 48rem !important;
  top: 15rem;
  .ant-modal-content {
    background-color: #f6f8ff;
    .ant-modal-header {
      padding: 2.5rem 3rem;
      border-bottom: none;
      margin-bottom: 0;
      background-color: transparent;
      .ant-modal-title {
        font-size: 2.4rem;
        line-height: 2.6rem;
        font-family: $font-primary-bold;
      }
    }
    .ant-modal-close {
      margin: 2rem 2.5rem 0 0;
      .ant-modal-close-x {
        padding: 0.5rem;
        width: fit-content;
        height: fit-content;
        line-height: normal;
        .anticon {
          color: $color-black;
        }
      }
    }
    .ant-modal-body {
      .invite-to-ai-chat-modal-container {
        padding: 0 3rem 2.5rem;
        &__input-feild {
          .tagify {
            border: none;
            background-color: $color-white;
            padding: 0;
            min-height: 4rem;
            max-height: 7.5rem;
            border: 1px solid rgba(1, 30, 41, 0.151);
            &__input {
              border: none;
              padding: 1rem 1.4rem;
              height: 4rem;
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-primary;
            }
          }
        }
        &__btn {
          font-size: 1.5rem;
          font-family: $font-primary-medium;
          width: 12rem;
          margin-top: 4rem;
          margin-left: auto;
          display: block;
          height: 4rem;
          border: none;
          background-color: $color-primary;
          color: $color-white;
          padding: 0;
          cursor: pointer;
          span {
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>

<template>
  <a-drawer
    placement="left"
    :closable="true"
    :visible="drawerVisibility"
    wrapClassName="ai-mobile-drawer"
    width="80vw"
    :title="null"
    @close="$emit('close-drawer')"
  >
    <ai-sidebar></ai-sidebar>
  </a-drawer>
</template>

<script>
import AiSidebar from "./AISidebar.vue";

export default {
  components: {
    AiSidebar,
  },
  props: {
    drawerVisibility: Boolean,
  },
};
</script>

<style lang="scss">
.ai-mobile-drawer {
  .ant-drawer-content-wrapper {
    height: 100vh !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header-no-title {
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }
        .ant-drawer-body {
          height: 100%;
          padding: 0 2rem;
          .ai-sidebar {
            border-right: none;
            &__body {
              height: calc(100vh - 19rem);
            }
          }
        }
      }
    }
  }
}
</style>

<template>
    <div class="ai-sidebar">
        <div class="ai-sidebar__header">
            <div class="ai-sidebar__header--title">
                <p>Previous Searches</p>
                <a-tooltip placement="bottom">
                    <template #title>New chat</template>
                    <button class="btn" @click="newChat()">
                        <img src="@/assets/icons/new-chat-icon.png" />
                    </button>
                </a-tooltip>
            </div>
            <div class="ai-sidebar__header--search-bar">
                <i class="icon icon-search" @click.stop=""></i>
                <input class="search-input" type="text" placeholder="Search" v-model="searchSession" @input="onInput" />
                <!-- <div class="filter-dropdwon">
                    <button @click="filterDropdown()" class="filter-dropdwon__btn">
                        <svg
                        width="19"
                        height="13"
                        viewBox="0 0 19 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M7.17578 12.0674V10.0674H11.1758V12.0674H7.17578ZM3.17578 7.06738V5.06738H15.1758V7.06738H3.17578ZM0.175781 2.06738V0.0673828H18.1758V2.06738H0.175781Z"
                            fill="#1C1B1F"
                        />
                        </svg>
                    </button>
                    <ul v-if="isDropdownOpen" class="filter-dropdwon__dropdown-menu">
                        <li @click="selectDate('alphabeticalKeyword')">
                        <span>Alphabetical Keyword</span>
                        </li>
                        <li @click="selectDate('Date')">
                        <span>Today</span>
                        <a-date-picker
                            v-if="isDatePickerVisible"
                            status="warning"
                            style="width: 100%;"
                            @change="handleDateChange"
                            :open="true"
                        />
                        </li>
                    </ul>
                </div> -->
      </div>
    </div>
    <div class="ai-sidebar__body">
      <div
        class="search-card"
        v-for="(searchCard, index) in sessionsList"
        :key="index"
      >
        <div class="search-card__details" @click=" updateUrl(searchCard.id)">
          <h1 v-if="!searchCard.isEditing" class="search-card__details--title">
            {{ searchCard.name }}
          </h1>
          <textarea
            v-if="searchCard.isEditing"
            v-model="newTitle"
            class="search-card__details--textarea"
            @keyup.enter="saveTitle(index)"
            @blur="saveTitle(index)"
            ref="textarea"
            rows="1"
          ></textarea>
          <button v-if="searchCard.isEditing" @click="cancelEdit(index)" class="search-card__details--cancel-btn">Cancel</button>
          <p class="search-card__details--count">{{ searchCard.total_threads }} Conversation threads</p>
        </div>
        <a-dropdown
          :trigger="['click']"
          class="search-card__dropdown"
          placement="bottomRight"
        >
          <a class="dropdown-link" @click="toggleDropdown(index)">
            <i class="icon icon-vertical_three-dots"></i>
          </a>
        <template #overlay>
          <a-menu>
            <a-menu-item key="0" @click="editTitle(searchCard, index)">
                <a>Rename</a>
            </a-menu-item>
            <a-menu-item key="1" @click="deleteCard(searchCard)">
                <a class="remove-btn">Delete</a>
            </a-menu-item>
          </a-menu>
        </template>
        </a-dropdown>
      </div>
    </div>
  </div>
  <delete-modal
    :visible="sessionDelete"
    @handle-close="deleteChat = false"
    title="Are you sure you want to delete this Chat?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    :loading="deleteChat"
    @delete-record="deleteSession"
  ></delete-modal>
</template>

<script>
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import { mapActions,mapGetters } from 'vuex';
export default {
    data() {
        return {
            sessions: [],
            searchSession: '',
            newTitle: '',
            editedTitle: '',
            isDatePickerVisible: false,
            isDropdownOpen: false,
            sessionList: [],
            filterToday:true,
            deleteChat:false,
            showDeletSession:false,
            session:null
        }
    },
    components: {
    DeleteModal,
    },

    watch: {
        '$route.params.id': {
            handler(id) {
                if (id) {
                    let session = this.sessionsList.find((session) => {
                        if (session.id == id) {
                            return session
                        }
                    });
                    this.setSession(session)
                }
                else {
                    this.setSession()
                }
            },
            immediate: true
        }
    },
    mounted() {
    },

    computed: {
        ...mapGetters({
        sessionChat: 'aiChat/sessionChat',
        }),
        sessionDelete(){
           return this.$store.getters['aiChat/isDelete']
        },
        sessionsList() {
            let list = this.$store.getters['aiChat/sessions'];

            if (this.searchSession.length > 0) {
                list = list.filter((session) => {
                    if (session.name.toLowerCase().includes(this.searchSession.toLowerCase())) {
                        return session
                    }
                })
            }

            return list
        },

        sessionSelected() {
            return this.$store.state.aiChat.sessionSelected;
        }
    },

    methods: {
        ...mapActions({
            getMessages: 'aiChat/getMessages',
            setActiveSession: 'aiChat/setActiveSession',
            emptySectionChats: 'aiChat/emptySectionChats',
            toggleChatSectionSkeleton: 'aiChat/toggleChatSectionSkeleton'
        }),
        editTitle(session, index) {
            this.sessionsList[index].isEditing = true;
            this.newTitle = session.name
            this.editedTitle = session.name
        },
        cancelEdit(index) {
            this.newTitle = this.editedTitle
            this.sessionsList[index].isEditing = false;
        },
        saveTitle(index) {
            if (this.sessionsList[index].name.trim() !== "") {
                this.sessionsList[index].name = this.newTitle;
                this.$store.dispatch('aiChat/editSessionName', this.sessionsList[index])
            }
            this.sessionsList[index].isEditing = false;
        },
        toggleDropdown(index) {
            this.sessionsList.forEach((card, i) => {
                card.isDropdownVisible = i === index ? !card.isDropdownVisible : false;
            });
        },
        deleteCard(session) {
            this.$store.commit("aiChat/SESSION_DELETE", true);
            this.session = session;
        },
        async deleteSession(){
            try {
                this.deleteChat=true;
                await this.$store.dispatch('aiChat/deleteSession', this.session)
            }  catch (error) {
                console.log('error',error)
            } finally{
                this.deleteChat = false;
            }
        },
        async setSession(session = null) {
            if (session) {
                this.setActiveSession(session)
                this.toggleChatSectionSkeleton()
                this.emptySectionChats()
                await this.getMessages(session.id)
                this.toggleChatSectionSkeleton()
            } else {
                this.setActiveSession(null)
                this.emptySectionChats()
            }
        },
        filterDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
            this.isDatePickerVisible = false;
        },
        selectDate(val) {
            if (val === "Date") {
                this.isDatePickerVisible = !this.isDatePickerVisible;
            }
        },

        updateUrl(id) {
            this.$emit("show-skeleton")
            this.$router.push(`/glocal-AI/${id}`);
        },
        newChat() {
            this.$router.push(`/glocal-AI`);
        }
    },

    async beforeCreate() {
        await this.$store.dispatch("aiChat/initiateSessions")
        this.sessions = [...this.$store.getters['aiChat/sessions']]
        if (this.$route.params.id) {
            const sectionId = this.$route.params.id
            let session = this.sessionsList.find((session) => {
                if (session.id === sectionId) {
                    return session
                }
            });
            this.setSession(session)
        }
    }
};
</script>

<style lang="scss">
.ai-sidebar {
    padding: 3rem 1rem 2rem 2rem;
    height: 100%;
    border-right: 1px solid $color-dark-grey-5;
    &__header {
        padding-bottom: 2.9rem;
        padding-right: 1rem;
        &--title {
            display: flex;
            align-items: center;
            margin-bottom: 2.6rem;
            p {
                font-size: 2.4rem;
                font-family: $font-primary-bold;
                margin-bottom: 0;
                color: $color-black;
                line-height: 2.8rem;
                margin-right: 1rem;
            }
            .btn {
                background-color: transparent;
                line-height: 2rem;
                border: none;
                outline: none;
                width: 2.4rem;
                margin-left: auto;
                display: block;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &--search-bar {
            width: 100%;
            margin: 0;
            display: flex;
            align-items: center;
            background-color: #e9e9e9;
            padding: 0 1.5rem 0 1rem;
            border-radius: 100px;
            .icon {
                font-size: 1.7rem;
                opacity: 1;
                color: $color-primary;
                margin-top: 0rem;
            }
            .search-input {
                font-size: 1.2rem;
                font-family: $font-primary;
                line-height: normal;
                padding: 1.2rem 1rem;
                border: none;
                outline: none;
                width: 100%;
                background-color: transparent;
                &::placeholder {
                    color: $color-black;
                    opacity: 0.5;
                }
            }
            .filter-dropdwon {
                margin-left: auto;
                position: relative;
                display: inline-block;
                padding-left: 2rem;
                &__btn {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: max-content;
                    margin-left: auto;
                    img {
                        width: 1.8rem;
                        margin-left: 2.4rem;
                    }
                }
                &__dropdown-menu {
                    display: block;
                    position: absolute;
                    background-color: $color-white;
                    box-shadow: 0px 4px 24.6px 0px #00000040;
                    z-index: 1;
                    right: 0;
                    border-radius: 0.8rem;
                    li {
                        padding: 1.3rem 1.6rem;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                        list-style: none;
                        display: block;
                        width: 100%;
                        font-family: $font-primary-medium;
                        line-height: 1.4rem;
                        font-size: 1.2rem;
                        border-bottom: 1px solid #0000001a;
                        border-radius: 0.8rem;
                        &:hover {
                            background-color: #F5F5F5;
                        }
                        span {
                            display: block;
                            width: max-content;
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                        .ant-calendar-picker {
                            .ant-calendar-picker-input,
                            .ant-calendar-picker-icon {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
    &__body {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 27rem);
        padding-right: 1rem;
        .search-card {
            border: 1px solid $color-dark-grey-5;
            padding: 1.5rem 1.8rem;
            background-color: #f4f4f4;
            border-radius: 1rem;
            display: flex;
            align-items: flex-start;
            margin-bottom: 2.4rem;
            cursor: pointer;
            &__details {
                width: 100%;
                padding-right: 1rem;
                &--title {
                    font-size: 1.4rem;
                    font-family: $font-primary-medium;
                    margin-bottom: 1rem;
                    color: $color-black;
                    line-height: 1.8rem;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* number of lines to show */
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                &--title.editing {
                    display: none;
                }
                &--textarea {
                    padding: 0;
                    width: 100%;
                    font-size: 1.4rem;
                    font-family: $font-primary-medium;
                    color: $color-black;
                    line-height: 1.8rem;
                    overflow: hidden;
                    resize: none;
                    margin-bottom: 1rem;
                    border: none;
                    outline: 1px solid $color-primary;
                    background-color: transparent;
                    box-sizing: border-box;
                    display: block;
                }
                &--cancel-btn {
                    font-size: 1rem;
                    line-height: 1rem;
                    margin-bottom: 0.5rem;
                    margin-top: -0.2rem;
                    display: block;
                    border: none;
                    font-family: $font-primary-medium;
                    margin-left: auto;
                    cursor: pointer;
                }
                &--count {
                    font-size: 1.2rem;
                    font-family: $font-primary;
                    margin-bottom: 0.2rem;
                    color: $color-black;
                    line-height: 1.4rem;
                    opacity: 0.6;
                }
            }
            &__dropdown {
                margin-right: -0.2rem;
                .icon {
                    font-size: 1.5rem;
                    color: $color-black;
                }
            }
        }
        &::-webkit-scrollbar-track {
            background-color: #f4f4f4;
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: rgb(224, 224, 224);
            border-radius: 3rem;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 3rem;
        }
    }
}
</style>
